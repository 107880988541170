import { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "helpers/auth";

const useAxios = ({ url, method, body = null, headers = null, responseState = null }) => {
    if (getToken()) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
        axios.defaults.headers.common["Content-Type"] = "application/json";
    }
    const [response, setResponse] = useState(responseState);
    const [error, setError] = useState("");
    const [loadingResponse, setLoadingResponse] = useState(true);
    const [status, setStatus] = useState("");
    useEffect(() => {
        axios[method](url, JSON.parse(headers), JSON.parse(body))
            .then((res) => {
                setResponse(res.data);
                setStatus(res.status);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoadingResponse(false);
            });
    }, [method, url, body, headers]);

    return { response, error, loadingResponse, status };
};

export default useAxios;
